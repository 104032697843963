@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sanchez:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Agbalumo&display=swap");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
@font-face {
	font-family: "Enthalpy 298";
	src: url("../../assets/fonts/Enthalpy\ 298.otf") format("truetype");
	font-weight: normal;
	font-style: normal;
}
body,
html {
	height: 100%;
	overflow-x: hidden;
}
body::-webkit-scrollbar {
	display: none;
}

.home_container {
	position: relative;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: auto;
	z-index: 0;
}
.home_overlay {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(5, 5, 5, 0.1);
	z-index: 0;
}
.home_container > * {
	position: absolute;
	z-index: 2;
}
.home_top_nav {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 2;
}
a {
	text-decoration: none;
}

.home_tree {
	position: relative;
	top: 0;
	z-index: 0;
}
.home_tree img {
	width: 100%;
	height: 100%;
}

.center_text {
	position: absolute;
	height: 500px;
	text-align: center;
	top: 48%;
	left: 50%;
	z-index: 2;
	transform: translate(-50%, -50%);
}
.discover_text {
	font-family: "Enthalpy 298";
	font-size: 140px;
	font-weight: 400;
	line-height: 140px;
	color: rgba(255, 255, 255, 1);
	margin-bottom: 2%;
}
.discover,
.highlight {
	display: block;
}
.highlight {
	color: rgba(255, 221, 103, 1);
}
.discover_sub_text {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-family: "Manrope";
	font-size: 30px;
	font-weight: 100;
	line-height: 30px;
	color: rgba(255, 255, 255, 1);
	margin-bottom: 4%;
}
.discover_highlight {
	font-weight: 700;
}
.explore_text {
	width: 280px;
	height: 60px;
	margin: 0 auto;
	border-radius: 35px;
	border: 1px solid rgba(255, 255, 255, 0.6);
	color: rgba(255, 255, 255, 1);
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: "Sanchez";
	font-size: 20px;
	font-weight: 400;
	cursor: pointer;
	line-height: 25px;
	background-color: rgba(36, 62, 28, 0.5);
	border: 1px solid #ffffff;
	background-clip: padding-box;
	box-shadow: 0 0 2px #ffffff;
	text-shadow: 0 0 1px #ffffff, 0 0 6px #ffffff;
}
.btm_img_home {
	width: 100%;
	background: none;
	position: absolute;
	z-index: 3;
	bottom: 0;
}
.btm_img_home img {
	width: 100%;
	height: 100%;
	background: transparent;
}
.tree-skeleton {
	background-color: #3c502b;
	height: 1000px;
	padding: 0;
	width: 100%;
	position: relative;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	z-index: 0;
}
/* .shimmer {
  animation: shimmer 10s infinite linear;
} */

/* SECOND PAGE */

.property_container {
	width: 100%;
	height: auto;
	background-color: rgb(32, 46, 20);
}

.property_img {
	width: 100%;
	position: relative;
	z-index: 5;
	margin-top: -8%;
	background-color: rgb(32, 46, 20);
}
.property_img img {
	width: 100%;
	height: 100%;
}
.bird_img {
	position: absolute;
	top: 7%;
	left: 33%;
}
.bird_img img {
	width: 12rem;
	height: 8rem;
}
.property_text {
	position: absolute;
	height: auto;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	padding: 0;
}
.who_text {
	height: auto;
	font-family: "Enthalpy 298";
	font-size: 100px;
	font-weight: 400;
	color: rgba(255, 255, 255, 1);
	line-height: 100px;
	margin-right: 15%;
}
.highlight_sub_text .highlight {
	display: block;
}
.para_text p {
	width: 700px;
	height: 9rem;
	font-family: "Manrope";
	font-size: 30px;
	font-weight: 400;
	color: rgba(255, 255, 255, 1);
	line-height: 45px;
	text-wrap: wrap;
}
/* THIRD PAGE */
.lifeAt_container {
	position: relative;
	width: 100%;
	background-color: rgb(32, 46, 20);
	height: auto;
}
.top_leaf_image {
	width: 100%;
	background: none;
	position: relative;
	z-index: 6;
	user-select: none;
	-webkit-user-drag: none;
	margin-top: -4.5%;
}
.top_leaf_image img {
	width: 100%;
	height: 100%;
}
.life_txt {
	position: absolute;
	margin: auto;
	top: 25%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-family: "Enthalpy 298";
	font-size: 100px;
	font-weight: 400;
	line-height: 100px;
	text-align: center;
	text-wrap: wrap;
	color: rgba(255, 255, 255, 1);
}
.life_center_box {
	position: relative;
	margin-top: -15%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 7;
}
.number {
	position: absolute;
	top: -40%;
	left: 80%;
	padding: 0;
	margin: 0;
	z-index: -1;
}
.number h1 {
	padding: 0 !important;
	margin: 0;
	font-family: "Agbalumo";
	font-size: 250px;
	font-weight: 400;
	line-height: 250px;
	text-align: left;
	color: #293d1a;
}

.box_align {
	margin: 0;
	padding: 0;
	position: relative;
	display: flex;
	gap: 40px;
	padding: 0;
	justify-content: center;
	align-items: center;
	z-index: 1;
}
.para_grp_text {
	width: 400px;
	height: auto;
	color: rgba(255, 255, 255, 1);
	position: relative;
	overflow: hidden;
	padding: 0;
}
.page-slide {
	margin: 0;
	padding: 0;
	overflow: hidden; /* Prevent scrollable content */
}

.para_grp_text .para1 {
	font-family: "Enthalpy 298";
	font-size: 60px;
	font-weight: 400;
	line-height: 61px;
	height: auto;
	padding: 0;
	width: 400px;
}
.para_grp_text .para2 {
	font-family: "Manrope";
	font-size: 30px;
	font-weight: 400;
	line-height: 45px;
	width: 400px;
	height: auto;
}
.vertical_box {
	width: 8px;
	height: 280px;
	background-color: #2d411c;
	border-radius: 8px;
	z-index: 1;
}
.box_scroll {
	width: 8px;
	height: calc(280px / var(--items-count)); /* Dynamically set height */
	border-radius: 8px;
	cursor: pointer;
	transition: background-color 0.3s ease;
	user-select: none;
}
.box_scroll.active {
	background-color: rgba(255, 221, 103, 1);
}
.homeImg {
	width: 38rem;
	height: auto;
	z-index: 1;
}
.homeImg img {
	width: 100%;
	height: 100%;
}
.highlighted {
	color: rgba(255, 221, 103, 1);
}
.second-btm-img {
	margin-top: -8%;
}
.second-btm-img img {
	width: 100%;
	height: 100%;
	background-color: rgb(32, 46, 20);
}
/* FOURTH PAGE */

.insight_container {
	width: 100%;
	height: auto;
}

/* FIFTH PAGE */
.about_container {
	position: relative;
	width: 100%;
	margin-top: -5%;
	z-index: 103;
}
.top_about img {
	width: 100%;
	height: 100%;
}
.map_img {
	position: relative;
	width: 100%;
	height: auto;
	z-index: -1;
	margin-top: -9%;
}
.map_img img {
	width: 100%;
	height: 100%;
}
.text_about_center {
	position: absolute;
	top: 25%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	padding: 0;
}
.about_text_1 {
	font-family: "Enthalpy 298";
	font-size: 100px;
	font-weight: 400;
	line-height: 100px;
	text-align: center;
	color: rgba(37, 53, 23, 1);
	margin-bottom: 1rem;
}
.about_text_2 {
	margin: auto;
	font-family: "Manrope";
	font-size: 30px;
	font-weight: 400;
	line-height: 45px;
	justify-content: center;
	text-align: center;
	color: rgba(0, 0, 0, 1);
}
.map_btm_img {
	position: relative;
	margin-top: -14%;
	width: 100%;
	height: auto;
}
.map_btm_img img {
	width: 100%;
	height: 100%;
}
/* SIXTH PAGE */
.service_container {
	position: relative;
	margin-top: -4px;
}

/* Below 768px */
@media (max-width: 767px) {
	.center_text {
		top: 52%;
		height: auto;
	}
	.discover_text {
		font-size: 20px;
		line-height: 20px;
		margin-bottom: 5px;
	}
	.discover_sub_text {
		font-size: 10px;
		line-height: 10px;
		margin-bottom: 10px;
	}
	.explore_text {
		font-size: 8px;
		line-height: 10px;
		width: 90px;
		height: 25px;
	}
	/* Second */
	.who_text {
		font-size: 20px;
		line-height: 20px;
	}
	.bird_img img {
		width: 50px;
		height: 35px;
	}
	.property_text {
		top: 48%;
		padding-inline: 20px;
	}
	.para_text p {
		width: 250px;
		height: 25px;
		font-size: 10px;
		line-height: 12px;
	}
	/* Third */
	.life_txt {
		font-size: 25px;
		line-height: 25px;
	}
	.life_center_box {
		margin-top: -15%;
		padding-inline: 20px;
	}
	.vertical_box {
		width: 4px;
		height: 130px;
	}
	.box_scroll {
		height: calc(130px / var(--items-count));
		width: 4px;
	}
	.box_align {
		gap: 10px;
	}
	.number {
		top: -20%;
	}
	.number h1 {
		font-size: 60px;
		line-height: 60px;
	}
	.para_grp_text {
		padding-top: 10px;
		height: 150px;
		width: 160px;
	}
	.para_grp_text .para1 {
		font-size: 20px;
		line-height: 20px;
		width: 160px;
	}
	.para_grp_text .para2 {
		font-size: 15px;
		line-height: 20px;
		width: 160px;
	}
	/* About */
	.about_container {
		margin-top: 0;
	}
	.text_about_center {
		top: 25%;
	}

	.about_text_1 {
		font-size: 25px;
		line-height: 25px;
		margin-top: 5%;
		margin-bottom: 0.5rem;
	}
	.about_text_2 {
		font-size: 10px;
		line-height: 10px;
		padding-inline: 5%;
	}
	/* SIXTH */
	.service_container {
		margin-top: 0;
	}
}

/* For screen widths between 768px and 1024px */
@media (min-width: 768px) and (max-width: 1023px) {
	/* FIRST */
	.center_text {
		height: auto;
	}
	.tree-skeleton {
		height: 450px;
	}
	.discover_text {
		font-size: 50px;
		font-weight: 400;
		line-height: 45px;
		color: rgba(255, 255, 255, 1);
	}

	.discover_sub_text {
		font-size: 20px;
		font-weight: 100;
	}

	/* SECOND */

	.property_img {
		margin-top: -3rem;
	}
	.property_img img {
		height: 32rem;
	}
	.bird_img img {
		top: 2.5rem;
		left: 18rem;
		width: 6rem;
		height: 4rem;
	}

	.property_text {
		padding: 0;
		top: 50%;
		padding: 0;
	}
	.who_text {
		font-size: 50px;
		line-height: 60px;
		padding: 0;
	}
	.para_text p {
		font-size: 20px;
		width: 480px;
		line-height: 30px;
		height: 8rem;
		margin: 0;
		padding-left: 5px;
	}
	/* THIRD */
	.lifeAt_container {
		margin-top: -2rem;
	}
	.life_txt {
		font-size: 50px;
		font-weight: 400;
		line-height: 60px;
		top: 30%;
	}
	.life_center_box {
		margin-top: -2rem;
	}
	.number {
		top: -80%;
	}
	.number h1 {
		font-size: 100px;
	}
	.para_grp_text {
		width: 260px;
		height: 200px;
	}
	.box_align {
		gap: 20px;
	}
	.vertical_box {
		height: 200px;
	}
	.para_grp_text .para1 {
		width: 260px;
		font-size: 40px;
		line-height: 40px;
	}
	.para_grp_text .para2 {
		width: 260px;
		font-size: 20px;
		line-height: 30px;
	}

	.box_scroll {
		height: 55px;
	}
	.second-btm-img {
		margin-top: -5rem;
	}
	/* FOURTH */
	.about_text_1 {
		font-size: 50px !important;
		line-height: 50px !important;
		margin-bottom: 0;
	}

	/* FIFTH */
	.about_container {
		margin-top: -7%;
	}

	.map_img {
		margin-top: -8%;
	}
	.text_about_center {
		top: 35%;
	}
	.top_about img {
		margin-top: 1rem;
	}

	.map_btm_img {
		margin-top: -8rem;
	}

	.about_text_1 {
		font-size: 70px;
		line-height: 85px;
	}
	.about_text_2 {
		font-size: 20px;
		line-height: 30px;
	}
}

/* For screen widths between 1024px and 1440px */
@media (min-width: 1024px) and (max-width: 1350px) {
	.center_text {
		height: auto;
	}
	.tree-skeleton {
		height: 630px;
	}
	.discover_text {
		font-size: 60px;
		font-weight: 400;
		line-height: 55px;
	}

	.discover_sub_text {
		font-size: 20px;
		font-weight: 100;
	}
	/* SECOND */
	.bird_img img {
		top: 2.5rem;
		left: 18rem;
		width: 7rem;
		height: 5rem;
	}

	.property_text {
		padding: 0;
		top: 50%;
		height: auto;
		padding: 0;
	}
	.who_text {
		font-size: 72px;
		height: auto;
		line-height: 72px;
		padding: 0;
	}
	.para_text p {
		font-size: 20px;
		width: 480px;
		line-height: 30px;
		height: 8rem;
		margin: 0;
		padding-left: 5px;
	}

	/* THIRD PAGE */
	/* .lifeAt_container {
  margin-top: -2.5rem;
} */
	.life_txt {
		font-size: 72px;
		font-weight: 400;
		line-height: 72px;
		top: 25%;
	}

	.para_grp_text {
		width: 260px;
		height: 200px;
	}
	.para_grp_text .para1 {
		width: 260px;
		font-size: 40px;
		line-height: 40px;
	}
	.para_grp_text .para2 {
		width: 260px;
		font-size: 20px;
		line-height: 30px;
	}
	.vertical_box {
		height: 200px;
	}
	.box_scroll {
		height: 55px;
	}
	.number h1 {
		font-size: 200px;
	}
	.number {
		top: -80%;
		left: 75%;
	}

	/* FOURTH PAGE */
	/* .about_container{
  margin-top: -5.5%;
} */
	.text_about_center {
		top: 35%;
	}
}

/* For screen widths 1920px and above */
@media (min-width: 1920px) {
	/* FIRST */
	.tree-skeleton {
		height: 1100px;
	}
	.discover_text {
		font-size: 150px;
	}
	/* SECOND */
	.bird_img {
		top: 10%;
	}
	.property_text {
		top: 45%;
	}

	/* THIRD */

	.life_txt {
		top: 28%;
	}

	.number {
		top: -55%;
	}
	.homeImg {
		width: 50rem;
	}
	.second-btm-img {
		margin-top: -8rem;
	}
}
@media (min-width: 2560px) {
	/* FIRST */
	.tree-skeleton {
		height: 1400px;
	}
	.center_text {
		top: 45%;
	}
	.discover_text {
		margin-bottom: 5%;
	}
	.discover_sub_text {
		margin-bottom: 10%;
	}
	.property_text {
		top: 40%;
	}
	.home_top_nav {
		top: 2rem;
	}
	/* SECOND */
	.bird_img {
		left: 55rem;
	}
	.property_img {
		margin-top: -12rem;
	}

	/* THIRD */
	.lifeAt_container {
		margin-top: -6.5rem;
	}
	.life_center_box {
		margin-top: -20rem;
	}
	.number {
		top: -60%;
	}

	.second-btm-img {
		margin-top: -12rem;
	}
	/* FOURTH */

	.map_img {
		margin-top: -10rem;
	}
	.map_btm_img {
		margin-top: -22rem;
	}

	/* FIFTH */
	.about_container {
		margin-top: -7rem;
	}
	.text_about_center {
		top: 25%;
	}
	.map_img {
		margin-top: -14rem;
	}
	.map_btm_img {
		margin-top: -22rem;
	}
	.life_center_box {
		margin-top: -22%;
	}
}
