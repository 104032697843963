.top_navbar {
	z-index: 1000;
	padding-top: 20px;
	width: 100%;
	flex-wrap: nowrap !important;
}
.nav_icon {
	width: 50px;
	height: 100%;
}
.nav_icon img {
	width: 100%;
	height: 100%;
}
.nav_list ul {
	margin: 0;
	padding: 0;
	gap: 10px;
}
.nav_list li {
	font-family: "Manrope";
	font-size: 16px;
	font-weight: 400;
	letter-spacing: 0.96px;
	color: rgba(255, 255, 255, 1);
	cursor: pointer;
	/* white-space: nowrap; */
	text-decoration: none;
}
.nav_list li:hover {
	transform: scale(1.03);
}

.nav_list li.active {
	color: rgba(255, 221, 103, 1);
	font-weight: 600;
}
.list-inline-item a {
	color: inherit !important;
	text-decoration: none;
}
button.contact_us_top a {
	color: inherit;
	text-decoration: none;
}
.contact_us_top {
	padding: 15px 20px;
	font-family: "Sanchez";
	font-size: 20px;
	font-weight: 400;
	background-color: rgb(36, 62, 28);
	border-radius: 96px;
	border: 1px solid rgba(255, 255, 255, 0.6);
	color: rgba(255, 255, 255, 1);
	cursor: pointer;
}
.contact_us_top:hover {
	transform: scale(1.03);
}

/* Below 768px */
@media (max-width: 767px) {
	.nav_icon {
		width: 40px;
		height: auto;
	}

	.nav_list ul {
		gap: 0;
		padding: 0;
	}
	.nav_list ul li {
		font-size: 6px;
		flex-wrap: nowrap;
	}
	.contact_us_top {
		font-size: 10px;
		padding: 10px 15px;
		border-radius: 20px;
		flex-wrap: nowrap;
	}
}
