* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
body,
html {
	height: 100%;
	overflow-x: hidden;
}
body::-webkit-scrollbar {
	display: none;
}
.forest_container {
	position: relative;
	width: 100%;
	height: auto;
	background-color: rgb(32, 46, 20);
	user-select: none;
	-webkit-user-drag: none;
}

.top_pic {
	position: relative;
	width: 100%;
	z-index: 0;
}

.top_pic img {
	width: 100%;
	height: fit-content;
	margin-top: -3%;
}

.img-first {
	width: 100%;
	height: 100%;
	position: relative;
}

.img-second {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	min-height: 100%;
}
.mountain_tree {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	z-index: 0;
	margin-top: -6%;
	padding: 0;
}
.img-third {
	width: 100%;
	height: 100%;
}
.img-fourth {
	position: absolute;
	top: 50%;
	width: 90%;
}
.text_slide {
	position: absolute;
	width: 100%;
	z-index: 1;
	padding: 0;
	top: 50px;
}
.txt_content {
	text-align: center;
	width: 100% !important;
	height: 265px;
	padding: 0;
	color: rgba(255, 255, 255, 1);
}
.txt_content h1 {
	font-family: "Enthalpy 298";
	font-size: 93px;
	width: 100%;
	font-weight: 400;
	line-height: 100px;
	text-align: center;
}
.txt_content p {
	font-family: "Manrope";
	font-size: 25px;
	font-weight: 400;
	line-height: 40px;
}
.txt_img_container {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.slide_pic {
	width: 680px;
	height: 475px;
}
.slide_pic img {
	width: 100%;
	height: fit-content;
}
.path {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: auto;
	z-index: 4;
	/* position: relative; */
	/* margin-top: -19%; */
}
.left_deer {
	position: relative;
	left: 5.5%;
	width: 250px;
	height: 200px;
}
.left_deer img {
	width: 100%;
	height: fit-content;
}
.com_path {
	position: relative;
	z-index: 5;
	width: 100%;
	height: auto;
	margin-top: -1%;
}
.com_path img {
	width: 100%;
	height: 100%;
}
.leftLeaf {
	position: absolute;
	top: 15%;
	left: 0;
	z-index: 6;
	width: 260px;
	height: 200px;
}
.leftLeaf img {
	width: 100%;
	height: fit-content;
}
.right_leaf {
	position: absolute;
	top: 35%;
	right: 0;
	z-index: 5;
	width: 250px;
	height: 100px;
}
.right_leaf img {
	width: 100%;
	height: fit-content;
}
.right_deer {
	position: absolute;
	top: 25%;
	right: 9%;
	z-index: 5;
	width: 250px;
	height: 400px;
}
.right_deer img {
	width: 100%;
	height: fit-content;
}

.group_path {
	position: relative;
	margin-top: -5%;
	z-index: 5;
}
.group_path img {
	width: 100%;
	height: 100%;
}
.dot_path {
	position: absolute;
	bottom: 50%;
	left: 50%;
	transform: translateX(-50%);
	color: wheat;
}
.next_content {
	cursor: pointer !important;
	display: flex;
}
.dot {
	width: 12px;
	height: 12px;
	border-radius: 10px;
	margin-right: 10px;
	background-color: #4c6e31;
}
.dot.active {
	background-color: #f8d243;
}
/* Below 768px */
@media (max-width: 767px) {
	.txt_content h1 {
		font-size: 25px;
		line-height: 25px;
	}
	.txt_content p {
		font-size: 10px;
		line-height: 10px;
		padding-inline: 5%;
	}
	.txt_content {
		height: auto;
	}
	.text_slide {
		top: 20px;
	}
	.slide_pic {
		width: 120px;
		height: auto;
	}
	.left_deer {
		width: 50px;
		height: 40px;
	}
	.leftLeaf {
		width: 50px;
		height: auto;
		top: 20%;
	}
	.path {
		bottom: -10%;
	}
	.right_leaf {
		width: 80px;
		top: 14%;
		height: auto;
	}
	.right_deer {
		top: 0;
		height: auto;
		width: 90px;
	}
	.dot {
		width: 5px;
		height: 5px;
	}
}

/* For screen widths between 768px and 1024px */
@media (min-width: 768px) and (max-width: 1023px) {
	.txt_content h1 {
		font-size: 50px;
		line-height: 50px;
	}
	.txt_content p {
		font-size: 15px;
		line-height: 23px;
	}

	.left_deer {
		width: 100px;
		left: 4%;
	}

	.leftLeaf {
		width: 100px;
		height: 120px;
		top: 50%;
	}
	.txt_content {
		height: 160px;
	}
	.left_deer img {
		margin-top: 120%;
	}

	.right_leaf {
		top: 50%;
		width: 150px;
		height: auto;
	}
	.right_deer {
		top: 40%;
		right: 10%;
		width: 150px;
		height: 100px;
	}
	.slide_pic {
		width: 300px;
		height: 300px;
	}
}

/* For screen widths between 1024px and 1440px */
@media (min-width: 1024px) and (max-width: 1350px) {
	.txt_content h1 {
		font-size: 75px;
		line-height: 80px;
	}
	.txt_content p {
		font-size: 25px;
		line-height: 35px;
	}
	.left_deer {
		width: 150px;
		left: 4%;
	}

	.leftLeaf {
		width: 130px;
		height: 120px;
		top: 40%;
	}
	.txt_content {
		height: 265px;
	}
	.left_deer img {
		margin-top: 50%;
	}

	.right_leaf {
		top: 50%;
		width: 150px;
		height: auto;
	}
	.right_deer {
		top: 30%;
		right: 7%;
		width: 200px;
	}

	.slide_pic {
		width: 350px;
		height: 300px;
	}
}
@media (min-width: 1600px) {
	.slide_pic {
		width: 800px;
		height: 475px;
	}
	.txt_content {
		height: 316px;
	}
	.txt_content h1 {
		margin-bottom: 30px;
	}
	.img-fourth {
		width: 95%;
	}
	.right_deer {
		top: 20%;
		right: 10%;
		width: 300px;
		height: 400px;
	}
	.right_leaf {
		width: 300px;
		height: 100px;
	}
}

/* For screen widths 1920px and above */
@media (min-width: 1920px) {
	.txt_content h1 {
		font-size: 100px;
	}
	.slide_pic {
		width: 1220px;
		height: 800px;
	}
	.right_deer {
		width: 360px;
		top: 10%;
		right: 8%;
	}
	.right_leaf {
		top: 30%;
	}
}

@media (min-width: 2560px) {
	.slide_pic {
		width: 1600px;
		height: auto;
	}
	.right_deer {
		width: 500px;
		right: 9.5%;
		top: 2%;
	}
	.right_leaf {
		width: 450px;
		top: 25%;
	}
	.left_deer {
		width: 300px;
	}
}
