@import url("https://fonts.googleapis.com/css2?family=Agbalumo&display=swap");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
body,
html {
	height: 100%;
	overflow-x: hidden;
}
.service_frst_container {
	width: 100%;
	height: auto;
	background-color: #202e14;
}
.service_navbar {
	width: 100%;
	height: auto;
	background-color: #3c502b;
}
.serv_top {
	position: relative;
	z-index: 2;
}
.serv_top img {
	width: 100%;
	height: auto;
	margin-top: -1%;
}
.service_center_box {
	position: relative;
	z-index: 1;
	width: 100%;
	margin-top: -5%;
	height: auto;
	color: #ffff;
	background-color: #202e14;
}
.service_center_box h1 {
	font-family: "Enthalpy 298";
	font-size: 150px;
	font-weight: 400;
	line-height: 140px;
	text-align: center;
	margin-bottom: 4rem;
}
.service_center_box p {
	font-family: "Manrope";
	font-size: 30px;
	font-weight: 700;
	line-height: 54px;
	text-align: center;
	margin-bottom: 5rem;
}
.service_highlight {
	color: #ffdd67;
}
.service_second_container {
	width: 100%;
	height: auto;
	margin-top: -10%;
	background-color: #202e14;
	padding: 10% 5%;
}
.green_load {
	width: 100%;
	margin-top: -10%;
	background-color: #202e14;
	padding: 10% 5%;
	height: 1200px;
}
.frst_btm_pic img {
	width: 100%;
	height: auto;
}
.sec_btm_pic {
	position: relative;
	top: 0;
	margin-top: -13%;
}
.sec_btm_pic img {
	width: 100%;
	height: auto;
}
.construction {
	position: relative;
	bottom: 0;
	width: 100%;
	height: auto;
	color: #ffff;
	background-color: #202e14;
	margin-top: -20%;
}
.construction_box {
	padding-inline: 2%;
}
.construct_1,
.construct-2,
.construct-3,
.construct-4 {
	display: flex;
	justify-content: space-between;
	margin-bottom: 3%;
}
.construct-2,
.construct-4 {
	gap: 2%;
}
.one_lft h1 {
	font-family: "Enthalpy 298";
	font-size: 100px;
	font-weight: 400;
	line-height: 100px;
	text-align: left;
	margin-bottom: 2%;
}
.one_lft p {
	font-family: "Manrope";
	font-size: 30px;
	font-weight: 400;
	line-height: 54px;
	text-align: left;
}
.one_rgt {
	margin: 0;
	padding: 0;
}

.one_rgt h1 {
	font-family: "Agbalumo";
	font-size: 250px;
	font-weight: 400;
	line-height: 250px;
	color: #293d1a;
	margin: 0;
	padding: 0;
	text-align: start;
	margin-top: -80px;
}

.rgt_pic,
.three_rgt_pic,
.four_pic {
	margin-bottom: 5%;
}
.two_rgt,
.four_rgt {
	width: 635px;
}
.two_rgt img,
.three_rgt_pic img,
.four_pic img {
	width: 100px;
	height: 100px;
}
.two_rgt h1,
.three_rgt h1,
.four_rgt h1 {
	font-family: "Enthalpy 298";
	font-size: 52px;
	font-weight: 400;
	line-height: 52px;
	text-align: left;
	margin-bottom: 5%;
}
.two_rgt p,
.three_rgt p,
.four_rgt p {
	font-family: "Manrope";
	font-size: 30px;
	font-weight: 400;
	line-height: 46px;
	text-align: left;
}
.three_rgt {
	width: 624px;
}
.sec_btm_pic_leaf {
	position: relative;
	margin-top: -15%;
}
.sec_btm_pic_leaf img {
	width: 100%;
	height: auto;
}
.three_lft,
.four_lft,
.two_lft {
	width: 50%;
	height: auto;
}
.three_lft img,
.four_lft img,
.two_lft img {
	width: 100%;
	height: auto;
}
.service_third_contatiner {
	position: relative;
	background-color: #202e14;
	color: #ffff;
	width: 100%;
	height: auto;
	padding: 0;
	border: none;
	outline: none;
}
.service_top {
	width: 100%;
	height: auto;
}
.service_top img {
	width: 100%;
	margin-top: -3%;
}
.security_services {
	width: 100%;
	height: auto;
	margin-top: 4%;
}
.security_1 {
	display: flex;
	justify-content: space-between;
	margin-bottom: 3%;
}
.seurity-lft h1 {
	font-family: "Enthalpy 298";
	font-size: 100px;
	font-weight: 400;
	line-height: 100px;
	text-align: left;
}
.security_2 {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 2rem;
	color: #ffff;
	margin-bottom: 3%;
}

.sec_2_first,
.sec_2_second {
	flex: 1;
}
.sec_2_first_wrapper {
	position: relative;
	z-index: 1;
}
.sec_2_first_wrap_pic {
	position: relative;
	z-index: 1;
}
.security_three_pic img {
	width: 100%;
	height: 60vh;
}
.overlay_image {
	position: absolute;
	top: 0;
	left: 0;
}

.overlay_image img {
	padding-top: 3px;
	padding-inline: 3px;
	width: 100%;
	height: auto;
	border-radius: 15px 15px 0px 0px;
	margin-bottom: 5%;
}
.overlay_image_three {
	position: absolute;
	top: 0;
	left: 0;
	width: 550px;
}
.overlay_image_three img {
	padding-top: 3px;
	object-fit: cover;
	height: 59.5vh;
	padding-inline: 3px;
	border-radius: 15px 10px 10px 15px;
}
.sec_2_first_text {
	width: 540px;
	height: 200px;
}
.text_container_1 {
	display: flex;
	gap: 5%;
	margin-top: 2%;
	margin-left: 3%;
}
.first_img img {
	width: 84px;
	height: 84px;
}
.first_text h1 {
	font-family: "Enthalpy 298";
	font-size: 42px;
	font-weight: 400;
	line-height: 42px;
	text-align: left;
}
.text_container_2 p {
	font-family: "Manrope";
	font-size: 30px;
	font-weight: 400;
	line-height: 46px;
	text-align: left;
	margin-left: 4%;
}
.security-3 {
	margin-bottom: 6rem;
	overflow: hidden;
}
.security_side_text {
	position: absolute;
	top: 0;
	right: 0;
	width: 50%;
	height: 60vh;
	display: grid;
	align-items: center;
}
.security_center_txt {
	padding-inline: 6%;
}
.security-4 {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}
.security-4 img {
	width: 100%;
	height: auto;
}
.green_txt {
	position: absolute;
	top: 0;
	width: 100%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: auto;
	text-align: center;
	padding-inline: 8%;
}
.green_txt h1 {
	font-family: "Enthalpy 298";
	font-size: 54px;
	font-weight: 400;
	line-height: 54px;
}
.green_txt p {
	font-family: "Manrope";
	font-size: 38px;
	font-weight: 400;
	line-height: 54px;
}
.servicemapbtm {
	position: relative;
	z-index: 1;
	width: 100%;
	height: auto;
	margin: 0;
	border: none;
	outline: none;
}
.servicemapbtm img {
	width: 100%;
	border: none !important;
}
.serviceFourthContainer {
	position: relative;
	width: 100%;
	height: auto;
	outline: none;
	margin-top: -1%;
}
/* Below 768px */
@media (max-width: 767px) {
	.service_frst_container {
		border: 1px solid transparent;
	}
	.service_center_box h1 {
		font-size: 50px;
		line-height: 50px;
		margin-bottom: 0.5rem;
	}
	.service_center_box p {
		font-size: 15px;
		line-height: 20px;
		margin-bottom: 2rem;
	}
	.construction_box {
		padding-inline: 10%;
	}
	.one_lft h1 {
		font-size: 50px;
		line-height: 50px;
		margin-bottom: 0.5rem;
	}
	.one_lft p {
		font-size: 15px;
		line-height: 20px;
	}
	.one_rgt h1 {
		font-size: 80px;
		line-height: 100px;
		margin-top: -40px;
	}
	.two_rgt h1,
	.three_rgt h1,
	.four_rgt h1 {
		font-size: 25px;
		line-height: 25px;
	}
	.two_rgt img,
	.three_rgt_pic img,
	.four_pic img {
		width: 50px;
		height: 50px;
	}
	.two_rgt p,
	.three_rgt p,
	.four_rgt p {
		font-size: 15px;
		line-height: 20px;
	}
	.seurity-lft h1 {
		font-size: 50px;
		line-height: 50px;
	}
	.security_2 {
		gap: 0.5rem;
	}
	.security_three_pic img {
		height: 20.5vh;
	}
	.security_side_text {
		height: 20.5vh;
	}
	.overlay_image img {
		width: 35.4%;
		padding-top: 1.5px;
		padding-inline: 2px;
		border-radius: 10px 10px 0px 0px;
		margin-bottom: 2%;
	}
	.text_container_1 {
		gap: 0;
		margin-top: 0;
	}
	.first_img img {
		width: 40px;
		height: 40px;
	}
	.first_text h1 {
		font-size: 15px;
		line-height: 15px;
		margin-bottom: 15px;
	}
	.text_container_2 p {
		font-size: 10px;
		line-height: 10px;
		width: 170px;
	}
	.overlay_image_three {
		width: 190px;
	}
	.overlay_image_three img {
		height: 20.5vh;
		padding-top: 2px;
		padding-inline: 2px;
		border-radius: 5px 10px 10px 10px;
	}
	.three_lft,
	.four_lft,
	.two_lft {
		width: 100%;
		height: auto;
	}
	.security-3 {
		margin-bottom: 2rem;
	}

	.green_txt h1 {
		font-size: 25px;
		line-height: 10px;
	}
	.green_txt p {
		font-size: 12px;
		line-height: 20px;
	}
}

/* For screen widths between 768px and 1024px */
@media (min-width: 768px) and (max-width: 1023px) {
	.service_center_box h1 {
		font-size: 50px;
		margin-bottom: 0.5rem;
		line-height: 60px;
	}
	.service_center_box p {
		font-size: 20px;
		line-height: 30px;
		margin-bottom: 2rem;
	}
	.sec_btm_pic {
		margin-top: -15%;
	}

	.construction_box {
		padding-inline: 10%;
	}
	.green_load {
		height: 600px;
	}
	.one_lft h1 {
		font-size: 50px;
		line-height: 60px;
	}
	.one_lft p {
		font-size: 20px;
		line-height: 30px;
	}
	.one_rgt h1 {
		font-size: 150px;
	}
	.rgt_pic,
	.three_rgt_pic,
	.four_pic {
		margin-bottom: 1rem;
	}
	.two_rgt img,
	.three_rgt_pic img,
	.four_pic img {
		width: 50px;
		height: 50px;
	}
	.two_rgt h1,
	.three_rgt h1,
	.four_rgt h1 {
		font-size: 35px;
		line-height: 45px;
		margin-bottom: 0.5rem;
	}
	.three_lft,
	.four_lft,
	.two_lft {
		width: 100%;
		height: auto;
	}

	.two_rgt p,
	.three_rgt p,
	.four_rgt p {
		font-size: 20px;
		line-height: 30px;
	}
	.seurity-lft h1 {
		font-size: 50px;
		line-height: 60px;
	}
	.overlay_image {
		width: 330px;
	}
	.first_img img {
		width: 50px;
		height: 50px;
	}
	.first_text h1 {
		font-size: 30px;
		line-height: 30px;
		margin-top: 0;
	}
	.text_container_2 p {
		font-size: 15px;
		line-height: 20px;
		width: 280px;
		height: 20px;
		margin-top: -5%;
	}
	.security_side_text,
	.overlay_image_three img,
	.security_three_pic img {
		height: 30vh;
	}
	.overlay_image_three {
		width: 350px;
	}
	.overlay_image_three img {
		padding-inline: 2px;
	}
	.overlay_image img {
		padding-inline: 1px;
		border-radius: 10px;
	}
	.green_txt h1 {
		line-height: 45px;
		font-size: 45px;
	}
	.green_txt p {
		font-size: 25px;
		line-height: 30px;
	}
}
/* For screen widths between 1024px and 1440px */
@media (min-width: 1024px) and (max-width: 1350px) {
	.green_load {
		height: 600px;
	}
	.sec_btm_pic {
		margin-top: -15%;
	}
	.construction_box {
		padding-inline: 10%;
	}
	.overlay_image {
		width: 450px;
	}
	.first_img img {
		width: 50px;
		height: 50px;
	}
	.first_text h1 {
		font-size: 30px;
		line-height: 35px;
		margin-top: 0;
	}
	.text_container_2 p {
		font-size: 15px;
		line-height: 20px;
		width: 280px;
	}
	.security_side_text,
	.overlay_image_three img,
	.security_three_pic img {
		height: 30vh;
	}
	.overlay_image_three {
		width: 450px;
	}
	.green_txt h1 {
		line-height: 45px;
		font-size: 45px;
	}
	.green_txt p {
		font-size: 25px;
		line-height: 30px;
	}
	.overlay_image_three img {
		padding-inline: 2px;
	}
	.overlay_image img {
		padding-inline: 1px;
	}
}
/* For screen widths 1440px and above */
@media (min-width: 1440px) {
	.overlay_image_three {
		width: 640px;
	}
}
/* For screen widths 1920px and above */
@media (min-width: 1920px) {
	.security_side_text,
	.security_three_pic img {
		height: 50vh;
	}
	.overlay_image_three img {
		height: 49.5vh;
	}
}

@media screen and (min-width: 2560px) {
	.green_load {
		height: 2000px;
	}

	.frst_btm_pic {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.frst_btm_pic img {
		width: 90%;
		height: auto;
	}
	.sec_btm_pic {
		margin-top: -22rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.sec_btm_pic img {
		width: 90%;
		height: auto;
	}
	.security_side_text,
	.security_three_pic img {
		height: 40vh;
	}
	.overlay_image_three img {
		height: 39.8vh;
	}
}
