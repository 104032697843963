* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
.form_skeleton {
	position: relative;
	top: 0;
	padding-bottom: 4%;
	width: 100%;
	height: 800px;
	background-color: #202e14;
}
.contact_first_container {
	position: relative;
	width: 100%;
	height: auto;
	background-color: #202e14;
}
.contact_navbar {
	width: 100%;
	height: auto;
	background-color: #3c502b;
}
.top_image {
	position: absolute;
	margin-top: -3%;
	width: 100%;
	height: auto;
}
.top_image img {
	width: 100%;
	height: auto;
}
.form_container {
	position: relative;
	width: 100%;
	top: 0;
	height: auto;
	padding-bottom: 4%;
	z-index: 2;
}
.inside_box {
	width: 100%;
	height: auto;
}
.box_1,
.box_2 {
	position: relative;
	top: 5rem;
}

.contact_box {
	width: 100%;
}
.border_box {
	position: relative;
	overflow: hidden;
}
.border_box img {
	width: 100%;
	height: auto;
}
.contact_content {
	position: absolute;
	top: 9%;
	width: 100%;
	height: auto;
	padding-inline: 4%;
}
.mandatory {
	color: red;
}
.error {
	color: red;
	font-size: 18px;
}
.userField.errorField {
	margin-bottom: 0;
}
.successMessage {
	font-size: 18px;
	font-weight: 400;
}
.title_box h1 {
	font-family: "Enthalpy 298";
	font-size: 40px;
	font-weight: 400;
	line-height: 41px;
	text-align: left;
	margin-bottom: 1rem;
}
.userLabel {
	width: 100%;
	font-family: "Sanchez";
	font-size: 20px;
	font-weight: 400;
	line-height: 25px;
	text-align: left;
	margin-bottom: 1rem;
}
.userField {
	width: 100%;
	height: 3rem;
	border-radius: 47px;
	border: 1px solid #202f14;
	padding-left: 1rem;
	margin-bottom: 2rem;
}

.userField:focus {
	box-shadow: 0 0 2px 5px #aeb6a7;
}
textarea.userField {
	padding-top: 10px;
	resize: none;
}
.country_city {
	width: 100%;
	display: flex;
	gap: 1rem;
}
.form_address,
.form_city {
	width: 100%;
}
.check_box {
	width: 100%;
	height: auto;
	margin-bottom: 1rem;
}
.custom-checkbox {
	width: 18px !important;
	height: 18px !important;
	border: 2px solid black !important;
	appearance: none;
	-webkit-appearance: none;
	cursor: pointer;
	border-radius: 2px;
	box-shadow: none !important;
}

.custom-checkbox:checked {
	background-color: rgb(60, 80, 43) !important;
	border: none !important;
	box-shadow: none !important;
}
.custom-checkbox:focus {
	outline: none;
	box-shadow: none !important;
}
.submit_btn {
	width: 100%;
	height: auto;
	text-align: center;
	background-color: rgb(60, 80, 43);
	border-radius: 96px;
	margin-bottom: 1rem;
	cursor: pointer;
}
.form_sub_btn {
	font-family: "Sanchez" !important;
	font-size: 20px;
	font-weight: 400 !important;
	line-height: 25px !important;
	color: #ffff !important;
}
.form_sub_btn:focus {
	outline: none;
	border: none;
}

.form_footer {
	font-family: "Inter";
	font-size: 18px;
	font-weight: 400;
	line-height: 22px;
	text-align: center;
}
.visit_box {
	margin-top: 10%;
	width: 100%;
	height: auto;
	text-wrap: wrap;
	z-index: 1;
	padding-left: 2rem;
}
.top_text h1 {
	width: 100% !important;
	height: auto !important;
	font-family: "Enthalpy 298";
	font-size: 85px;
	font-weight: 400;
	line-height: 86px;
	text-align: left;
	color: #ffff;
	margin-bottom: 4.5rem;
}
.top_text {
	height: auto !important;
}
.link_details {
	color: #ffff;
	margin: 0;
}
.link_details h1 {
	font-family: "Sanchez";
	font-size: 24px;
	font-weight: 400;
	line-height: 30px;
	text-align: left;
}
.mail_contact,
.write,
.general {
	display: flex;
	align-items: center;
}
.details_image {
	width: 24px;
	height: 32px;
	margin-right: 10px;
}
.details_text {
	font-family: "Sanchez";
	font-size: 24px;
	font-weight: 400;
	line-height: 30px;
	text-align: left;
	color: #f8e085;
}
.mail_text {
	width: 100%;
	height: auto;
	font-family: "Inter";
	font-size: 20px;
	font-weight: 400;
	line-height: 30px;
	margin-bottom: 2rem;
	/* letter-spacing: 2px; */
	text-align: left;
	cursor: pointer;
}
.media_links {
	display: flex;
	margin-top: 15px;
}
.link_outline {
	margin-right: 10px;
	cursor: pointer;
	width: 2.8rem;
	height: 2.8rem;
	border-radius: 30px;
	border: 1px solid rgba(255, 255, 255, 0.1);
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: transparent;
}
.contact_social_link_1 img {
	width: 8px;
	height: 17px;
}
.contact_social_link_2 img {
	width: 20px;
	height: 20px;
}
.contact_social_link_3 img {
	width: 15px;
	height: 15px;
}
.contact_social_link_4 img {
	width: 15px;
	height: 15px;
}
.contact_social_link_5 img {
	width: 22px;
	height: 16px;
}
.second_contact_container {
	width: 100%;
	height: auto;
}
.contact_btm {
	position: relative;
	margin-top: -2%;
	width: 100%;
	z-index: 1 !important;
	pointer-events: none !important;
}
.contact_btm img {
	width: 100%;
	pointer-events: none !important;
}
.hide_func {
	position: relative;
	z-index: 0;
	width: 100%;
	height: 2%;
	background-color: #202e14;
}
.contact_map {
	position: relative;
	z-index: 0;
	margin-top: -14%;
	height: 100vh;
}
.contact_map iframe {
	width: 100%;
	height: 100%;
	border: 1px solid none;
}
.map_bottom {
	position: relative;
	z-index: 1;
	width: 100%;
	height: auto;
	border: none;
	outline: none;
	margin-top: -16%;
	pointer-events: none !important;
}
.map_bottom img {
	width: 100%;
	border: none !important;
}
.contact_third_container {
	position: relative;
	width: 100%;
	height: auto;
	outline: none;
	margin-top: -3px;
}
/* Below 768px */
@media (max-width: 767px) {
	.box_1,
	.box_2 {
		top: 30px;
	}
	.visit_box {
		margin-top: 0;
		padding-left: 0;
	}
	.top_text h1 {
		font-size: 30px;
		line-height: 30px;
		margin-bottom: 20px;
	}
	.link_details h1 {
		font-size: 18px;
		line-height: 20px;
	}
	.mail_text {
		font-size: 15px;
		line-height: 20px;
		margin-bottom: 1rem;
		overflow-wrap: break-word;
	}
	.border_box {
		padding-top: 2%;
	}
	.border_box img {
		width: 100%;
		object-fit: cover;
		border-radius: 15px;
		height: 300px !important;
	}
	.contact_content {
		padding-inline: 2%;
		padding-top: 2%;
	}
	.title_box h1 {
		font-size: 18px;
		line-height: 10px;
		margin-bottom: 5px !important;
	}
	.userLabel {
		font-size: 10px;
		/* height: 18px; */
		/* margin: 0; */
		/* padding: 0; */
		padding-top: -20px !important;
		margin-bottom: 0;
		line-height: 10px;
	}
	.userField {
		height: 20px;
		line-height: 12px;
		margin-bottom: 0;
		font-size: 8px;
		/* border: 2px solid yellow; */
	}
	.submit_btn {
		margin-bottom: 0;
		height: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
	}
	.form_sub_btn {
		font-size: 10px;
		line-height: 20px;
	}
	.country_city {
		margin: 0;
		padding: 0 !important;
		margin-bottom: 10px;
	}
	.form_address,
	.form_city {
		padding: 0;
		margin: 0;
	}
	.error {
		font-size: 6px;
		margin-bottom: 0 !important;
	}
	textarea.userField {
		padding-top: 0;
	}
	.map_bottom {
		margin-top: -40%;
	}
}
@media (min-width: 1440px) {
	.border_box img {
		height: 730px;
	}
}

/* For screen widths between 768px and 1024px */
@media (min-width: 768px) and (max-width: 1023px) {
	.contact_content {
		top: 6%;
	}
	.border_box img {
		height: auto;
	}

	.userLabel {
		margin-bottom: 5px;
		font-size: 15px;
	}
	.userField {
		height: 1.8rem;
		margin-bottom: 5px;
	}
	.submit_btn {
		margin-top: 0.5rem;
		margin-bottom: 0rem;
	}
	.title_box h1 {
		font-size: 30px;
		line-height: 40px;
		margin-bottom: 0;
	}
	.top_text h1 {
		font-size: 50px;
		line-height: 60px;
		margin-bottom: 1rem;
	}
	textarea.userField {
		padding-top: 3px;
	}

	.map_bottom {
		margin-top: -20%;
	}
	.box_1,
	.box_2 {
		top: 3rem;
	}
	.visit_box {
		margin-top: 0;
	}
	.error {
		margin-bottom: 0;
	}
}
/* For screen widths between 1024px and 1440px */
@media (min-width: 1024px) and (max-width: 1350px) {
	.contact_content {
		top: 10%;
	}
	.border_box img {
		height: auto;
	}
	.userField {
		height: 2rem;
		margin-bottom: 15px;
	}
	.submit_btn {
		margin-top: 1rem;
	}
	.top_text h1 {
		margin-bottom: 1rem;
	}
	.map_bottom {
		margin-top: -25%;
	}
	.error {
		margin-bottom: 0;
	}
	textarea.userField {
		padding-top: 3px;
	}
	.box_1,
	.box_2 {
		top: 4rem;
	}
	.visit_box {
		margin-top: 10px;
	}
}
@media (min-width: 1440px) {
	.contact_content {
		padding-inline: 10%;
	}
}
/* For screen widths 1920px and above */
@media (min-width: 1920px) {
	.form_skeleton {
		height: 1000px;
	}
}

@media screen and (min-width: 2560px) {
	.form_skeleton {
		height: 900px;
	}
	.contact_box {
		margin-top: 10%;
	}
}
