body,
html {
	margin: 0;
	padding: 0;
	font-family: "Poppins";
}
.notFound {
	padding: 40px 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}
.details {
	border-radius: 10px;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
	padding: 20px 20px;
	background-color: #ffffff;
}

h1 {
	padding: 10px 0;
	font-weight: bolder;
	font-size: clamp(1.8rem, 4vw, 2.2rem);
}
p {
	font-size: 15px;
}
.details a {
	font-family: "Inter";
	display: inline-block; /* Ensure it takes up space */
	margin-top: 10px;
	text-decoration: none;
	color: #007bff !important; /* Set a visible color */
	font-size: 16px;
	padding: 10px 15px;
	font-weight: bold;
	border-radius: 6px;
}

.details a:hover {
	transform: scale(1.03);
}
